import { template as template_555b5b1849e745f2b17cd6be975de31f } from "@ember/template-compiler";
const WelcomeHeader = template_555b5b1849e745f2b17cd6be975de31f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
