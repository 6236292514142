import { template as template_5987af1cf2af48ec89050667b653e7c4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_5987af1cf2af48ec89050667b653e7c4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
