import { template as template_93da83cc87754bb7a0f22a083a3104be } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_93da83cc87754bb7a0f22a083a3104be(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
