import { template as template_8df47685b4b94d05b1d40f2db5f300ca } from "@ember/template-compiler";
const FKLabel = template_8df47685b4b94d05b1d40f2db5f300ca(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
