import { template as template_1646794edcbd4916bea03ea763d69a69 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1646794edcbd4916bea03ea763d69a69(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
